import { useEffect, useState, useContext } from "react";
import useAxios from "../../commonHooks/useAxios";
import { leadFormContext } from "../../context/leadFormContext";
import { Product } from "../Interfaces";

export interface ProdctsByGroup {
    title : string;
    products : Product[];
}

export default function useStep1() {
    const [loading, setLoading] = useState<boolean>(false);
    const [productsArray, setProductsArray] = useState<ProdctsByGroup[]>([]);
    const [productsId, setProductsId] = useState<number[]>([]);
    const [next, setNext] = useState<boolean>(false);
    const { setProducts, setStep } = useContext(leadFormContext);
    const sendData = useAxios();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const productsResponse: Product[] = await sendData({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL}/products`,
                headers: {
                    accept: "*/*",
                },
            });
            // create an array of products by group
            const productsByGroup: ProdctsByGroup[] = [];
            productsResponse.forEach((product) => {
                const group = product.name;
                const index = productsByGroup.findIndex((item) => item.title === group);
                if (index === -1) {
                    productsByGroup.push({
                        title: group,
                        products: [product],
                    });
                } else {
                    productsByGroup[index].products.push(product);
                }
            });
            setProductsArray(productsByGroup);
            setLoading(false);
        }
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function handleProductsId(id: number) {
        if (productsId.includes(id)) {
            const newProductsId = productsId.filter((item) => item !== id);
            setProductsId(newProductsId);
            setProducts(newProductsId.sort().toString());
            if (newProductsId.length === 0) setNext(false);
        } else {
            setProductsId([...productsId, id]);
            setProducts([...productsId, id].sort().toString());
            setNext(true);
        }
    }

    function handleNext() {
        if (next) setStep(2);
    }

    return {
        loading,
        productsArray,
        productsId,
        handleProductsId,
        next,
        handleNext
    }
}
