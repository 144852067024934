import { useState, useContext, useCallback } from "react";
import { leadFormContext } from "../../context/leadFormContext";
import { useJsApiLoader } from "@react-google-maps/api";
import MarkerIcon from "../../assets/img/markerIcon.png";
import { notificationsContext } from "../../context/notificationsContext";

export default function useStep2() {
    const [next, setNext] = useState<boolean>(false);
    const { map, setStep, setMap, address, addressNumber, city, province, marker, setMarker, setLat, setLng } = useContext(leadFormContext);
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyCymfXuGTA2G-CBaKnXZJHRuswtOCc9uLM",
    });
    const [errors, setErrors] = useState<any>({});
    const [confirmUbication, setconfirmUbication] = useState<string>("");
    const { setNotification } = useContext(notificationsContext);


    function handleNext() {
        if (next) setStep(3);
    }

    function handleBack() {
        setStep(1);
    }

    const onLoad = useCallback(function callback(map: google.maps.Map) {
        setMap(map);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUnmount = useCallback(function callback(map: google.maps.Map) {
        setMap(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function validateForm() {
        const errors: any = {};
        let isValid = true;
        if (!address) {
            setErrors((prev: any) => ({
                ...prev,
                address: "La calle es requerida.",
            }));
            errors.address = "La calle es requerida.";
            isValid = false;
        }
        if (!addressNumber) {
            setErrors((prev: any) => ({
                ...prev,
                addressNumber: "La altura es requerida.",
            }));
            errors.addressNumber = "La altura es requerida.";
            isValid = false;
        }
        if (!city) {
            setErrors((prev: any) => ({
                ...prev,
                city: "La localidad es requerida.",
            }));
            errors.city = "La localidad es requerida.";
            isValid = false;
        }
        if (!province) {
            setErrors((prev: any) => ({
                ...prev,
                province: "La provincia es requerida.",
            }));
            errors.province = "La provincia es requerida.";
            isValid = false;
        }
        if (Object.keys(errors).length > 0) {
            setNotification({
                theme: "error",
                titleNotification: "Formulario Incompleto",
                contentNotification: errors[Object.keys(errors)[0]],
                isVisible: true,
            });
        }
        setTimeout(() => {
            setErrors({});
        }, 3000);
        return isValid;
    }

    function searchMap() {
        if (!validateForm()) return;
        if (marker) {
            marker.setMap(null);
        }
        const service = new google.maps.places.PlacesService(map as any);
        service.textSearch(
            {
                query: `${address} ${addressNumber} ${city} ${province}`,
                location: new google.maps.LatLng(-34.6, -58.43),
                region: "AR",
            },
            (results: any, status: any) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    const marker = new window.google.maps.Marker({
                        position: {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng(),
                        },
                        map: map,
                        draggable: false,
                        icon: {
                            url: MarkerIcon,
                            scaledSize: new window.google.maps.Size(25, 38),
                        },
                    });
                    setMarker(marker);
                    if (map) {
                        map.setCenter({
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng(),
                        });
                        setLat(results[0].geometry.location.lat());
                        setLng(results[0].geometry.location.lng());
                        setNext(true);
                        map.setZoom(15);
                    }
                    setconfirmUbication(results[0].formatted_address);
                }
            }
        );
    }


    return {
        next,
        handleNext,
        handleBack,
        onLoad,
        onUnmount,
        isLoaded,
        errors,
        confirmUbication,
        searchMap
    }
}
