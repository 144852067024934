import axios, { AxiosRequestConfig } from "axios";
import { getAuth } from "firebase/auth";
import { useContext } from "react";
import { userContext } from "../context/userContext";

export default function useAxios() {
  const auth = getAuth();
  const {
    userState: { user },
  } = useContext(userContext);
  const fetchData = async (params: AxiosRequestConfig) => {
    const accesToken = window.localStorage.getItem("accessToken");
    const dateToken = window.localStorage.getItem("dateToken");
    const difTime =
      (new Date().getTime() - new Date(String(dateToken)).getTime()) /
      1000 /
      60;
    let config = {
      ...params,
    };

    if (accesToken) {
      if (difTime >= 50) {
        const refreshToken = await auth.currentUser?.getIdToken();
        config = {
          ...config,
          headers: {
            Authorization: `Bearer ${refreshToken}`,
            "impersonate-id": `${user?.tokenImpersonated && user.users_id}`
          },
        };
        window.localStorage.setItem("accessToken", String(refreshToken));
        window.localStorage.setItem("dateToken", new Date().toISOString());
      } else {
        config = {
          ...config,
          headers: {
            Authorization: `Bearer ${accesToken}`,
            "impersonate-id": `${user?.tokenImpersonated && user.users_id}`
          },
        };
      }
    } else {
      const refreshToken = await auth.currentUser?.getIdToken();
      config = {
        ...config,
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          "impersonate-id": `${user?.tokenImpersonated && user.users_id}`
        },
      };
      window.localStorage.setItem("accessToken", String(refreshToken));
      window.localStorage.setItem("dateToken", new Date().toISOString());
    }
    try {
      const result = await axios.request(config);
      return result.data;
    } catch (err: any) {
      const refreshToken = await auth.currentUser?.getIdToken();
      window.localStorage.setItem("accessToken", String(refreshToken));
      window.localStorage.setItem("dateToken", new Date().toISOString());
      try {
        const result = await axios.request(config);
        return result.data;
      } catch (error) {
        console.log(error);
      }
    }
  };
  return fetchData;
}
