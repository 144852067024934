import { createContext, useState } from "react";

interface INotificationsContext {
  notification: {
    isVisible: boolean;
    titleNotification: string;
    contentNotification: string;
    theme: string;
  };
  setNotification: (notification: {
    isVisible: boolean;
    titleNotification: string;
    contentNotification: string;
    theme: string;
  }) => void;
}

const documentsNotContext = createContext<INotificationsContext>({
  notification: {
    isVisible: false,
    titleNotification: "",
    contentNotification: "",
    theme: "",
  },
  setNotification: () => {},
});

const DocumentNotificationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [notification, setNotification] = useState({
    isVisible: false,
    titleNotification: "",
    contentNotification: "",
    theme: "",
  });
  return (
    <documentsNotContext.Provider
      value={{
        notification,
        setNotification,
      }}
    >
      {children}
    </documentsNotContext.Provider>
  );
};

export { DocumentNotificationProvider, documentsNotContext };
