import styled from "styled-components";
import CheckIcon from "../../assets/img/checkPermissionIcon.svg";
import Button from "../../commonComponents/Button";
import useStep1 from "../hooks/useStep1";
import { ProdctsByGroup } from "../hooks/useStep1";
import { Product } from "./../Interfaces";

export default function Step1() {
  const { productsArray, productsId, handleProductsId, next, handleNext } =
    useStep1();

  return (
    <Container>
      <Title>¿En qué producto tenés Interés?</Title>
      <Products>
        {productsArray.map((group: ProdctsByGroup, index) => {
          return (
            <div key={index}>
              <ProductTitle>{group.title}</ProductTitle>
              {group.products.map((product: Product) => {
                return (
                  <ProductContainer key={product.products_id}>
                    <Checkbox
                      onClick={() => {
                        handleProductsId(product.products_id);
                      }}
                    >
                      {productsId.includes(product.products_id) && (
                        <img src={CheckIcon} alt="check icon" />
                      )}
                    </Checkbox>
                    <ProductDetails>
                      <ProductDescription>
                        {product.description
                          ? product.description
                          : group.title}
                      </ProductDescription>
                    </ProductDetails>
                  </ProductContainer>
                );
              })}
            </div>
          );
        })}
      </Products>
      <ButtonsContainer>
        <Button type={next ? "primary" : "disabled"} onClick={handleNext}>
          SIGUIENTE
        </Button>
      </ButtonsContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 26px;
  padding: 14px 34px;
  min-height: 80vh;
`;
const Title = styled.h3`
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #424242;
`;
const Products = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
`;
const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px 0px;
`;
const Checkbox = styled.div`
  min-width: 24px;
  min-height: 24px;
  border: 1px solid #424242;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`;
const ProductTitle = styled.h3`
  font-family: "D-DIN-PRO-Bold";
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #2c2c2c;
`;
const ProductDescription = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 5px 0px;
`;
