import styled from "styled-components";

export default function LeadCreated() {
  return (
    <Container>
      <Title>Solicitud enviada.</Title>
      <SubTitle>Gracias por su interés en nuestros productos.</SubTitle>
      <SubTitle>
        Hemos informado al asesor comercial sobre su interes acerca de los
        productos de YPF-Solar para que se comunique con usted.
      </SubTitle>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 26px;
  padding: 34px 34px;
  min-height: 80vh;
`;
const Title = styled.h3`
  font-weight: 700;
  font-family: "D-DIN-PRO-Bold";
  font-size: 15px;
  line-height: 15px;
  color: #424242;
  text-align: center;
`;
const SubTitle = styled.h3`
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #424242;
  text-align: center;
`;
