import styled from "styled-components";
import Button from "../../commonComponents/Button";
import { useContext } from "react";
import { leadFormContext } from "../../context/leadFormContext";
import useStep5 from "../hooks/useStep5";
import WhatsappIcon from "../../assets/img/whatsappIcon.svg";
import CircularProgress from "@mui/material/CircularProgress";

export default function Step5() {
  const { agentSelected, quantityAgents } = useContext(leadFormContext);
  const { handleContact, loading, completeType, handleOtherAgent } = useStep5();

  return (
    <Container>
      {loading && (
        <LoadingContainer>
          <CircularProgress
            sx={{
              color: "#128297",
            }}
            size={100}
          />
        </LoadingContainer>
      )}
      {!loading && !completeType && (
        <>
          <TopContainer>
            <Title>
              Te informamos los datos del representante seleccionado.
            </Title>

            {agentSelected?.logo && (
              <LogoContainer>
                <Logo
                  src={`${process.env.REACT_APP_API_URL}/getLogo/${agentSelected?.agents_id}`}
                  alt="Logo agent"
                />
              </LogoContainer>
            )}

            <FieldsContainer>
              <TwofieldsContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={agentSelected?.trade_name}
                  />
                  <LabelDisabled color={undefined}>
                    Nombre Comercial
                  </LabelDisabled>
                </FieldContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={agentSelected?.contact_phone}
                  />
                  <LabelDisabled color={undefined}>Teléfono</LabelDisabled>
                </FieldContainer>
              </TwofieldsContainer>
              <FieldContainer>
                <DisabledInput
                  disabled
                  required
                  value={
                    agentSelected?.operational_street +
                    " " +
                    agentSelected?.operational_address_number +
                    ", " +
                    agentSelected?.operational_city +
                    ", " +
                    agentSelected?.operational_province
                  }
                />
                <LabelDisabled color={undefined}>Ubicación</LabelDisabled>
              </FieldContainer>
              <TwofieldsContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={agentSelected?.contact_email}
                  />
                  <LabelDisabled color={undefined}>Mail</LabelDisabled>
                </FieldContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={agentSelected?.contact_name}
                  />
                  <LabelDisabled color={undefined}>Contacto</LabelDisabled>
                </FieldContainer>
              </TwofieldsContainer>
            </FieldsContainer>
          </TopContainer>
          <ButtonsContainer>
            <ButtonWhatsapp
              href={`https://wa.me/549${agentSelected?.contact_phone}`}
              target="_blank"
            >
              <Icon>
                <img src={WhatsappIcon} alt="whatsapp" />
              </Icon>
              <TextButton>enviar un mensaje vía whatsapp</TextButton>
            </ButtonWhatsapp>
            <ButtonsBottom>
              <Button type="secondary" onClick={() => handleContact("agent")}>
                Quiero que me contacte
              </Button>
              <Button type="secondary" onClick={() => handleContact("lead")}>
                Yo lo voy a contactar
              </Button>
            </ButtonsBottom>
            {quantityAgents > 1 && (
              <ButtonOtherAgent onClick={handleOtherAgent}>
                <TextButton>SELECCIONAR OTRO DISTRIBUIDOR</TextButton>
              </ButtonOtherAgent>
            )}
          </ButtonsContainer>
        </>
      )}
      {completeType && completeType === "agent" && (
        <ContainerCompleted>
          <TitleCompleted>Solicitud enviada.</TitleCompleted>
          <SubTitle>
            Hemos informado al distribuidor sobre su interes acerca de los
            productos de YPF-Solar. En breve se pondrá en contacto con usted.
          </SubTitle>
          <SubTitle>Gracias por su interés en nuestros productos.</SubTitle>
          {quantityAgents > 1 && (
            <ButtonOtherAgent onClick={handleOtherAgent}>
              <TextButton>SELECCIONAR OTRO DISTRIBUIDOR</TextButton>
            </ButtonOtherAgent>
          )}
        </ContainerCompleted>
      )}
      {completeType && completeType === "lead" && (
        <ContainerCompleted>
          <TitleCompleted>Datos enviados.</TitleCompleted>
          <SubTitle>
            Le hemos enviado un mail con los datos del distribuidor
            seleccionado.
          </SubTitle>
          <SubTitle>Gracias por su interés en nuestros productos.</SubTitle>
          {quantityAgents > 1 && (
            <ButtonOtherAgent onClick={handleOtherAgent}>
              <TextButton>SELECCIONAR OTRO DISTRIBUIDOR</TextButton>
            </ButtonOtherAgent>
          )}
        </ContainerCompleted>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 26px;
  padding: 14px 34px;
  min-height: 80vh;
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 26px;
`;
const Title = styled.h3`
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #424242;
`;
const TitleCompleted = styled.h3`
  font-weight: 700;
  font-family: "D-DIN-PRO-Bold";
  font-size: 15px;
  line-height: 15px;
  color: #424242;
  text-align: center;
`;
const SubTitle = styled.h3`
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #424242;
  text-align: center;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 5px 0px;
`;
const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  gap: 20px;
  @media (max-width: 768px) {
    gap: 10px;
  }
`;
const FieldContainer = styled.div`
  position: relative;
  height: 55px;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
  }
`;
const DisabledInput = styled.input`
  width: 100%;
  height: 64px;
  background: #ffffff;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const LabelDisabled = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 10px;
  left: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  @media (max-width: 768px) {
    top: 3px;
    left: 10px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #424242;
  }
`;
const TwofieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  @media (max-width: 768px) {
    gap: 10px;
  }
`;
const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const Logo = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100px;
  object-fit: contain;
`;
const ButtonWhatsapp = styled.a`
  background: #128297;
  width: 100%;
  max-width: 400px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  transition: 0.3s ease all;
  text-decoration: none;
  &:hover {
    background: #0f6a7a;
  }
  @media (max-width: 768px) {
    padding: 13px 10px;
    gap: 15px;
  }
`;
const Icon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;
const TextButton = styled.p`
  font-family: "D-DIN-PRO-Bold";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  text-transform: uppercase;
  color: #ffffff;
`;
const ButtonsBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  max-width: 600px;
  @media (max-width: 768px) {
    gap: 5px;
  }
`;
const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-height: 60vh;
`;
const ContainerCompleted = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 26px;
  padding: 34px 34px;
  min-height: 60vh;
`;
const ButtonOtherAgent = styled.button`
  background: #128297;
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  transition: 0.3s ease all;
  text-decoration: none;
  &:hover {
    background: #0f6a7a;
  }
  @media (max-width: 768px) {
    padding: 13px 10px;
    gap: 15px;
  }
`;
