import { useState, useContext, useEffect } from "react";
import { leadFormContext } from "../../context/leadFormContext";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";

interface AgentWithDistance {
    agents_id: string;
    trade_name: string;
    distance: number;
    latitude: number;
    longitude: number;
    operational_province: string;
    operational_city: string;
    operational_street: string;
    operational_address_number: string;
    contact_phone: string;
    contact_name: string;
    contact_email: string;
    logo: boolean;
}

interface Response {
    distance: boolean;
    agents: AgentWithDistance[];
}

export default function useStep3() {
    const [loading, setLoading] = useState<boolean>(false);
    const [agents, setAgents] = useState<AgentWithDistance[]>([])
    const [initialAgents, setInitialAgents] = useState<AgentWithDistance[]>([])
    const { step, setStep, lat, lng, setDistance, firstName, lastName, phone, email, products, address, addressNumber, city, province, distance, setCompletedForm, setAgentSelected, setQuantityAgents } = useContext(leadFormContext);
    const sendData = useAxios();
    const [errors, setErrors] = useState<any>({});
    const [isByDistance, setIsByDistance] = useState<boolean>(false);
    const { setNotification } = useContext(notificationsContext);

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            const agentsResponse: Response = await sendData({
                method: "POST",
                url: `${process.env.REACT_APP_API_URL}/leads/distance`,
                headers: {
                    accept: "*/*",
                },
                data: {
                    lat,
                    lng
                }
            });
            if (agentsResponse.distance) {
                setInitialAgents(agentsResponse.agents);
                const agentsWithDistance = agentsResponse.agents.filter((agent) => distance === 0 ? agent.distance <= 1 : agent.distance <= distance);
                agentsWithDistance.sort(function (a: AgentWithDistance, b: AgentWithDistance) {
                    if (a.distance > b.distance) return 1;
                    if (a.distance < b.distance) return -1;
                    return 0;
                });
                setAgents(agentsWithDistance);
                setQuantityAgents(agentsWithDistance.length);
                setIsByDistance(true);
            } else {
                setInitialAgents(agentsResponse.agents);
                setAgents(agentsResponse.agents);
                setQuantityAgents(agentsResponse.agents.length);
                setIsByDistance(false);
            }
            setLoading(false);
        }

        if (step === 3) {
            fetch();
        }
    }, [step, lat, lng]) // eslint-disable-line react-hooks/exhaustive-deps

    function handleBack() {
        setStep(2);
    }

    function handleDistance(distance: number) {
        const agentsWithDistance = initialAgents.filter((agent) => distance === 0 ? agent.distance <= 1 : agent.distance <= distance);
        agentsWithDistance.sort(function (a: AgentWithDistance, b: AgentWithDistance) {
            if (a.distance > b.distance) return 1;
            if (a.distance < b.distance) return -1;
            return 0;
        });
        setAgents(agentsWithDistance);
        setQuantityAgents(agentsWithDistance.length);
        setDistance(distance);
    }

    function validateForm() {
        const errors: any = {};
        const regexEmail =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = true;
        if (!firstName) {
            setErrors((prev: any) => ({
                ...prev,
                firstName: "El nombre es requerido.",
            }));
            errors.firstName = "El nombre es requerido.";
            isValid = false;
        }
        if (!lastName) {
            setErrors((prev: any) => ({
                ...prev,
                lastName: "El apellido es requerido.",
            }));
            errors.lastName = "El apellido es requerido.";
            isValid = false;
        }
        if (!regexEmail.test(String(email).toLowerCase())) {
            setErrors((prev: any) => ({
                ...prev,
                email: "El mail debe ser válido.",
            }));
            errors.email = "El mail debe ser válido.";
            isValid = false;
        }
        if (!phone) {
            setErrors((prev: any) => ({
                ...prev,
                phone: "El número de celular es requerido.",
            }));
            errors.phone = "El número de celular es requerido.";
            isValid = false;
        }
        if (Object.keys(errors).length > 0) {
            setNotification({
                theme: "error",
                titleNotification: "Formulario Incompleto",
                contentNotification: errors[Object.keys(errors)[0]],
                isVisible: true,
            });
        }
        setTimeout(() => {
            setErrors({});
        }, 3000);
        return isValid;
    }

    function handleContact(agent: AgentWithDistance) {
        setStep(4);
        setAgentSelected(agent);
    }

    async function handleSubmit() {
        if (!validateForm()) return;

        try {
            const data = {
                products,
                address: address + " " + addressNumber,
                city,
                province,
                lat,
                lng,
                distance,
                first_name: firstName,
                last_name: lastName,
                email,
                phone,
            }
            const response = await sendData({
                method: "POST",
                url: `${process.env.REACT_APP_API_URL}/leads`,
                headers: {
                    accept: "*/*",
                },
                data
            });
            if (response) {
                setCompletedForm(true);
                setNotification({
                    theme: "success",
                    titleNotification: "Solicitud enviada",
                    contentNotification: "Se ha registrado su solicitud de distribuidor.",
                    isVisible: true,
                });
            } else {
                setNotification({
                    theme: "error",
                    titleNotification: "Error de solicitud",
                    contentNotification: "Ha ocurrido un error en la solicitud. Vuelva a intenterlo.",
                    isVisible: true,
                });
            }
        } catch (error) {
            setNotification({
                theme: "error",
                titleNotification: "Error de solicitud",
                contentNotification: "Ha ocurrido un error en la solicitud. Vuelva a intenterlo.",
                isVisible: true,
            });
            console.log(error);
        }
    }

    return {
        handleBack,
        loading,
        agents,
        handleDistance,
        errors,
        handleSubmit,
        handleContact,
        isByDistance
    }
}
