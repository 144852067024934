import { useContext } from "react";
import { leadFormContext } from "../../context/leadFormContext";

export default function useLeadsForm() {
    const { step, setStep } = useContext(leadFormContext);

    return {
        step,
        setStep
    }
}
