import { useState, useContext } from "react";
import { leadFormContext } from "../../context/leadFormContext";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";

export default function useStep5() {
    const [loading, setLoading] = useState<boolean>(false);
    const [completeType, setCompleteType] = useState<null | "lead" | "agent">(null)
    const sendData = useAxios();
    const { setNotification } = useContext(notificationsContext);
    const { lat, lng, firstName, lastName, phone, email, products, address, addressNumber, city, province, distance, agentSelected, setStep } = useContext(leadFormContext);

    async function handleContact(type: "lead" | "agent") {
        setLoading(true);
        try {
            const data = {
                products,
                address: address + " " + addressNumber,
                city,
                province,
                lat,
                lng,
                distance,
                first_name: firstName,
                last_name: lastName,
                email,
                phone,
                agents_id: agentSelected?.agents_id,
                contact: type

            }
            const response = await sendData({
                method: "POST",
                url: `${process.env.REACT_APP_API_URL}/leads`,
                headers: {
                    accept: "*/*",
                },
                data
            });
            if (response) {
                if (type === "lead") {
                    setNotification({
                        theme: "success",
                        titleNotification: "Datos enviados",
                        contentNotification: "Se ha enviado un correo con los datos del distribuidores seleccionado.",
                        isVisible: true,
                    });
                    setCompleteType("lead");
                    setLoading(false);
                } else {
                    setNotification({
                        theme: "success",
                        titleNotification: "Solicitud enviada",
                        contentNotification: "Se ha registrado su solicitud de distribuidor. Pronto nos comunicaremos con usted. ",
                        isVisible: true,
                    });
                    setCompleteType("agent");
                    setLoading(false);
                }
            } else {
                setNotification({
                    theme: "error",
                    titleNotification: "Error de solicitud",
                    contentNotification: "Ha ocurrido un error en la solicitud. Vuelva a intenterlo.",
                    isVisible: true,
                });
                setLoading(false);
            }
        } catch (error) {
            setNotification({
                theme: "error",
                titleNotification: "Error de solicitud",
                contentNotification: "Ha ocurrido un error en la solicitud. Vuelva a intenterlo.",
                isVisible: true,
            });
            console.log(error);
            setLoading(false);
        }
    }

    function handleOtherAgent() {
        setStep(3);
        setCompleteType(null);
    }

    return {
        handleContact,
        loading,
        completeType,
        handleOtherAgent
    }
}
