import styled from "styled-components";
import Button from "../../commonComponents/Button";
import { useContext } from "react";
import { leadFormContext } from "../../context/leadFormContext";
import useStep3 from "../hooks/useStep3";
import { CircularProgress } from "@mui/material";
import ContactIcon from "../../assets/img/contactIcon.svg";

interface AgentWithDistance {
  agents_id: string;
  trade_name: string;
  distance: number;
  latitude: number;
  longitude: number;
  operational_province: string;
  operational_city: string;
  operational_street: string;
  operational_address_number: string;
  contact_phone: string;
  contact_name: string;
  contact_email: string;
  logo: boolean;
}

export default function Step3() {
  const {
    handleBack,
    agents,
    handleDistance,
    loading,
    errors,
    handleSubmit,
    handleContact,
    isByDistance,
  } = useStep3();
  const {
    distance,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
  } = useContext(leadFormContext);

  return (
    <Container>
      <TopContainer>
        {isByDistance ? (
          <Title>
            A continuación se encuentran los representantes de tu zona. Podés
            modificar el radio de cercanía{" "}
            <Span>hasta los 150km desde tu ubicación</Span>
          </Title>
        ) : (
          <Title>
            A continuación se encuentran los representantes de tu zona.
          </Title>
        )}

        {isByDistance && (
          <RangeContainer>
            <RangeValue>0km</RangeValue>
            <RangeInput>
              <LineInput />
              <LineColorInput distance={distance} />
              <RangeCont>
                <RangeText distance={distance}>
                  <p>{distance}</p>
                  <p>km</p>
                </RangeText>
              </RangeCont>
              <Range
                type="range"
                min="0"
                max="150"
                step={10}
                value={distance}
                onChange={(e) => {
                  handleDistance(Number(e.target.value));
                }}
              />
            </RangeInput>
            <RangeValue>150km</RangeValue>
          </RangeContainer>
        )}

        {loading ? (
          <Loading>
            <CircularProgress
              sx={{
                color: "#128297",
              }}
              size={100}
            />
          </Loading>
        ) : agents.length > 0 ? (
          <AgentsContainer>
            <Title>Representantes cercanos a tí</Title>
            <Agents>
              {agents.map((agent: AgentWithDistance) => (
                <AgentContainer key={agent.agents_id}>
                  <AgentName>{agent.trade_name}</AgentName>
                  {isByDistance && (
                    <AgentDistance>
                      Distancia {Number(agent.distance).toFixed(2)} km
                    </AgentDistance>
                  )}
                  <AgentProvince>{agent.operational_province}</AgentProvince>
                  <AgentCity>{agent.operational_city}</AgentCity>
                  <AgentLink>
                    <p onClick={() => handleContact(agent)}>Ver contacto</p>{" "}
                    <img src={ContactIcon} alt="icon" />
                  </AgentLink>
                </AgentContainer>
              ))}
            </Agents>
          </AgentsContainer>
        ) : (
          <BottomContainer>
            <BoldText>
              No contamos con representantes para la zona y el radio
              seleccionado
            </BoldText>
            <Subtitle>
              Un Representante Comercial de YPF SOLAR se contactará contigo para
              brindarte la información que necesitas.
            </Subtitle>
            <Subtitle>Por favor nos podés brindar tus datos:</Subtitle>
            <FieldsContainer>
              <FieldContainer>
                <Input
                  color={errors.firstName ? "#FF3D00" : undefined}
                  required
                  value={firstName}
                  onChange={({ target: { value } }) => setFirstName(value)}
                />
                <Label color={errors.firstName ? "#FF3D00" : undefined}>
                  Nombre
                </Label>
              </FieldContainer>
              <FieldContainer>
                <Input
                  color={errors.lastName ? "#FF3D00" : undefined}
                  required
                  value={lastName}
                  onChange={({ target: { value } }) => setLastName(value)}
                />
                <Label color={errors.lastName ? "#FF3D00" : undefined}>
                  Apellido
                </Label>
              </FieldContainer>
              <FieldContainer>
                <Input
                  color={errors.email ? "#FF3D00" : undefined}
                  required
                  value={email}
                  onChange={({ target: { value } }) => setEmail(value)}
                />
                <Label color={errors.email ? "#FF3D00" : undefined}>Mail</Label>
              </FieldContainer>
              <FieldContainer>
                <Input
                  color={errors.phone ? "#FF3D00" : undefined}
                  required
                  value={phone}
                  onChange={({ target: { value } }) => setPhone(value)}
                />
                <Label color={errors.phone ? "#FF3D00" : undefined}>
                  Número de Celular
                </Label>
              </FieldContainer>
            </FieldsContainer>
          </BottomContainer>
        )}
      </TopContainer>
      <ButtonsContainer>
        <Button type={"secondary"} onClick={handleBack}>
          ATRÁS
        </Button>
        {agents.length === 0 && (
          <Button type={"primary"} onClick={handleSubmit}>
            Enviar y finalizar
          </Button>
        )}
      </ButtonsContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 26px;
  padding: 14px 34px;
  min-height: 80vh;
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 26px;
`;
const Title = styled.h3`
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #424242;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 5px 0px;
`;
const Span = styled.span`
  font-family: "D-DIN-PRO-Medium";
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  color: #000000;
`;
const RangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 5px 0px;
`;
const Range = styled.input`
  width: 100%;
  height: 5px;
  background: transparent;
  border-radius: 5px;
  outline: none;
  -webkit-appearance: none;
  z-index: 20;
  &::-webkit-slider-thumb {
    z-index: 20;
    -webkit-appearance: none;
    appearance: none;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: transparent;
    cursor: pointer;
  }
`;
const RangeValue = styled.span`
  font-family: "D-DIN-PRO-Medium";
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  color: #000000;
`;
const RangeInput = styled.div`
  width: 100%;
  position: relative;
  z-index: 10;
  height: 24px;
`;
const LineInput = styled.div`
  width: 100%;
  height: 5px;
  background: #e7e7e7;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: -1;
`;
const LineColorInput = styled.div<{ distance: number }>`
  width: ${(props) => (props.distance / 150) * 100}%;
  height: 5px;
  background: #128297;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: -1;
`;
const RangeText = styled.span<{ distance: number }>`
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: #128297;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  top: 50%;
  left: ${(props) => (props.distance / 150) * 100}%;
  transform: translate(-50%, -50%);
  z-index: -1;
  p {
    font-family: "D-DIN-PRO-Bold";
    font-weight: 600;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
  }
`;
const RangeCont = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  padding: 0 18px;
`;
const AgentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
  margin-top: 20px;
`;
const Agents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
  max-height: 300px;
  overflow-y: scroll;
  //change scrollbar
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #128297;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #0e616f;
  }
  @media (max-width: 768px) {
    padding-right: 5px;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
    }
  }
`;
const AgentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;
const AgentName = styled.p`
  font-family: "D-DIN-PRO-Medium";
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  width: 20%;
`;
const AgentDistance = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  width: 20%;
`;
const AgentProvince = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  width: 20%;
  color: #424242;
  text-transform: capitalize;
`;
const AgentCity = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  width: 20%;
  color: #424242;
  text-transform: capitalize;
`;
const AgentLink = styled.a`
  width: 20%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  p {
    font-family: "D-DIN-PRO-bold";
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    text-decoration-line: underline;
    color: #128297;
  }
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Subtitle = styled.p`
  font-size: 15px;
  line-height: 20px;
  color: #424242;
  font-weight: 400;
`;
const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;
const BoldText = styled.p`
  font-family: "D-DIN-PRO-Bold";
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  color: #424242;
  margin-bottom: 20px;
`;
const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  gap: 20px;
  @media (max-width: 768px) {
    gap: 10px;
  }
`;
const FieldContainer = styled.div`
  position: relative;
  height: 55px;
  width: 100%;
  max-width: 400px;
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
  }
`;
const Input = styled.input`
  width: 100%;
  height: 55px;
  background: #ffffff;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const Label = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 11.5px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      line-height: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
