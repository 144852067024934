import { createContext, useState } from "react";

interface AgentWithDistance {
  agents_id: string;
  trade_name: string;
  distance: number;
  latitude: number;
  longitude: number;
  operational_province: string;
  operational_city: string;
  operational_street: string;
  operational_address_number: string;
  contact_phone: string;
  contact_name: string;
  contact_email: string;
  logo: boolean;
}


interface ILeadFormConext {
  leadsId: number;
  setLeadsId: (leads_id: number) => void;
  products: string;
  setProducts: (products: string) => void;
  address: string;
  setAddress: (address: string) => void;
  addressNumber: string;
  setAddressNumber: (addressNumber: string) => void;
  city: string;
  setCity: (city: string) => void;
  province: string;
  setProvince: (province: string) => void;
  lat: number;
  setLat: (lat: number) => void;
  lng: number;
  setLng: (lng: number) => void;
  distance: number;
  setDistance: (distance: number) => void;
  firstName: string;
  setFirstName: (first_name: string) => void;
  lastName: string;
  setLastName: (last_name: string) => void;
  email: string;
  setEmail: (email: string) => void;
  phone: string;
  setPhone: (phone: string) => void;
  step: number;
  setStep: (step: number) => void;
  map: any;
  setMap: (map: any) => void;
  marker: any;
  setMarker: (marker: any) => void;
  completedForm: boolean;
  setCompletedForm: (completedForm: boolean) => void;
  agentSelected: AgentWithDistance | null;
  setAgentSelected: (agentSelected: AgentWithDistance) => void;
  quantityAgents: number;
  setQuantityAgents: (quantityAgents: number) => void;
}

const leadFormContext = createContext<ILeadFormConext>({
  leadsId: 0,
  setLeadsId: (leads_id: number) => {},
  products: "",
  setProducts: (products: string) => {},
  address: "",
  setAddress: (address: string) => {},
  addressNumber: "",
  setAddressNumber: (addressNumber: string) => {},
  city: "",
  setCity: (city: string) => {},
  province: "",
  setProvince: (province: string) => {},
  lat: 0,
  setLat: (lat: number) => {},
  lng: 0,
  setLng: (lng: number) => {},
  distance: 0,
  setDistance: (distance: number) => {},
  firstName: "",
  setFirstName: (first_name: string) => {},
  lastName: "",
  setLastName: (last_name: string) => {},
  email: "",
  setEmail: (email: string) => {},
  phone: "",
  setPhone: (phone: string) => {},
  step: 1,
  setStep: (step: number) => {},
  map: null,
  setMap: (map: any) => {},
  marker: null,
  setMarker: (marker: any) => {},
  completedForm: false,
  setCompletedForm: (completedForm: boolean) => {},
  agentSelected: null,
  setAgentSelected: (agentSelected: AgentWithDistance) => {},
  quantityAgents: 0,
  setQuantityAgents: (quantityAgents: number) => {},
});

const LeadFormProvider = ({ children }: { children: React.ReactNode }) => {
  const [leadsId, setLeadsId] = useState(0);
  const [products, setProducts] = useState("");
  const [address, setAddress] = useState("");
  const [addressNumber, setAddressNumber] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [distance, setDistance] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [step, setStep] = useState(1);
  const [map, setMap] = useState<null | google.maps.Map>(null);
  const [marker, setMarker] = useState<any>(null);
  const [completedForm, setCompletedForm] = useState(false);
  const [agentSelected, setAgentSelected] = useState<AgentWithDistance | null>(null);
  const [quantityAgents, setQuantityAgents] = useState(0);
  return (
    <leadFormContext.Provider
      value={{
        leadsId,
        setLeadsId,
        products,
        setProducts,
        address,
        setAddress,
        addressNumber,
        setAddressNumber,
        city,
        setCity,
        province,
        setProvince,
        lat,
        setLat,
        lng,
        setLng,
        distance,
        setDistance,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        phone,
        setPhone,
        step,
        setStep,
        map,
        setMap,
        marker,
        setMarker,
        completedForm,
        setCompletedForm,
        agentSelected,
        setAgentSelected,
        quantityAgents,
        setQuantityAgents,
      }}
    >
      {children}
    </leadFormContext.Provider>
  );
};

export { LeadFormProvider, leadFormContext };
