import { useState, useContext } from "react";
import { leadFormContext } from "../../context/leadFormContext";
import { notificationsContext } from "../../context/notificationsContext";

export default function useStep4() {
    const { setStep, firstName, lastName, phone, email } = useContext(leadFormContext);
    const [errors, setErrors] = useState<any>({});
    const { setNotification } = useContext(notificationsContext);

    function validateForm() {
        const errors: any = {};
        const regexEmail =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = true;
        if (!firstName) {
            setErrors((prev: any) => ({
                ...prev,
                firstName: "El nombre es requerido.",
            }));
            errors.firstName = "El nombre es requerido.";
            isValid = false;
        }
        if (!lastName) {
            setErrors((prev: any) => ({
                ...prev,
                lastName: "El apellido es requerido.",
            }));
            errors.lastName = "El apellido es requerido.";
            isValid = false;
        }
        if (!regexEmail.test(String(email).toLowerCase())) {
            setErrors((prev: any) => ({
                ...prev,
                email: "El mail debe ser válido.",
            }));
            errors.email = "El mail debe ser válido.";
            isValid = false;
        }
        if (!phone) {
            setErrors((prev: any) => ({
                ...prev,
                phone: "El número de celular es requerido.",
            }));
            errors.phone = "El número de celular es requerido.";
            isValid = false;
        }
        if (Object.keys(errors).length > 0) {
            setNotification({
                theme: "error",
                titleNotification: "Formulario Incompleto",
                contentNotification: errors[Object.keys(errors)[0]],
                isVisible: true,
            });
        }
        setTimeout(() => {
            setErrors({});
        }, 3000);
        return isValid;
    }

    function handleNext() {
        if (!validateForm()) return;
        setStep(5);
    }
    
    function handleBack() {
        setStep(3);
    }

    return {
        handleBack,
        errors,
        handleNext
    }
}
