import styled from "styled-components";
import Button from "../../commonComponents/Button";
import { useContext } from "react";
import { leadFormContext } from "../../context/leadFormContext";
import useStep4 from "../hooks/useStep4";

export default function Step4() {
  const { handleBack, errors, handleNext } = useStep4();

  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
  } = useContext(leadFormContext);

  return (
    <Container>
      <TopContainer>
        <Title>
          Para brindarte los datos de nuestros representantes en tu zona
          necesitamos que nos brindes la siguiente información.
        </Title>

        <FieldsContainer>
          <FieldContainer>
            <Input
              color={errors.firstName ? "#FF3D00" : undefined}
              required
              value={firstName}
              onChange={({ target: { value } }) => setFirstName(value)}
            />
            <Label color={errors.firstName ? "#FF3D00" : undefined}>
              Nombre
            </Label>
          </FieldContainer>
          <FieldContainer>
            <Input
              color={errors.lastName ? "#FF3D00" : undefined}
              required
              value={lastName}
              onChange={({ target: { value } }) => setLastName(value)}
            />
            <Label color={errors.lastName ? "#FF3D00" : undefined}>
              Apellido
            </Label>
          </FieldContainer>
          <FieldContainer>
            <Input
              color={errors.email ? "#FF3D00" : undefined}
              required
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
            />
            <Label color={errors.email ? "#FF3D00" : undefined}>Mail</Label>
          </FieldContainer>
          <FieldContainer>
            <Input
              color={errors.phone ? "#FF3D00" : undefined}
              required
              value={phone}
              onChange={({ target: { value } }) => setPhone(value)}
            />
            <Label color={errors.phone ? "#FF3D00" : undefined}>
              Número de Celular
            </Label>
          </FieldContainer>
        </FieldsContainer>
      </TopContainer>
      <ButtonsContainer>
        <Button type={"secondary"} onClick={handleBack}>
          ATRÁS
        </Button>
        <Button type={"primary"} onClick={handleNext}>
          SIGUIENTE
        </Button>
      </ButtonsContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 26px;
  padding: 14px 34px;
  min-height: 80vh;
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 26px;
`;
const Title = styled.h3`
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #424242;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 5px 0px;
`;
const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  gap: 20px;
  @media (max-width: 768px) {
    gap: 10px;
  }
`;
const FieldContainer = styled.div`
  position: relative;
  height: 55px;
  width: 100%;
  max-width: 400px;
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
  }
`;
const Input = styled.input`
  width: 100%;
  height: 55px;
  background: #ffffff;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const Label = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 11.5px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      line-height: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
