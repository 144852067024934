import styled from "styled-components";
import CheckStep from "../../assets/img/checkStep.svg";

export default function StepButton({
  step,
  type,
}: {
  step: number;
  type: "check" | "current" | "next";
}) {
  return (
    <Step type={type}>
      {type === "check" ? <CheckStepImg src={CheckStep} alt="check" /> : step}
    </Step>
  );
}

const Step = styled.div<{ type: "check" | "current" | "next" }>`
  width: 32px;
  height: 32px;
  background: ${(props) => {
    switch (props.type) {
      case "check":
        return "#128297";
      case "current":
        return "#ffffff";
      case "next":
        return "#ffffff";
    }
  }};
  border: 1px solid
    ${(props) => {
      switch (props.type) {
        case "check":
          return "#128297";
        case "current":
          return "#128297";
        case "next":
          return "#e7e7e7";
      }
    }};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  color: ${(props) => {
    switch (props.type) {
      case "check":
        return "#ffffff";
      case "current":
        return "#424242";
      case "next":
        return "#e7e7e7";
    }
  }};
`;
const CheckStepImg = styled.img`
  width: 14px;
  height: 14px;
`;
