import styled from "styled-components";
import { GoogleMap } from "@react-google-maps/api";
import Button from "../../commonComponents/Button";
import useStep2 from "../hooks/useStep2";
import { useContext } from "react";
import { leadFormContext } from "../../context/leadFormContext";

const containerStyle = {
  width: "100%",
  height: "240px",
};

const center = {
  lat: -34.6,
  lng: -58.43,
};

const mapOptions = {
  center: center,
  zoom: 10,
  mapTypeId: "roadmap",
  disableDefaultUI: false,
  styles: [
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#444444",
        },
      ],
    },
    // {
    //   featureType: "landscape",
    //   elementType: "all",
    //   stylers: [
    //     {
    //       color: "#f2f2f2",
    //     },
    //   ],
    // },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    // {
    //   featureType: "road",
    //   elementType: "all",
    //   stylers: [
    //     {
    //       saturation: -100,
    //       lightness: 45,
    //     },
    //   ],
    // },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    // {
    //   featureType: "road.arterial",
    //   elementType: "labels.icon",
    //   stylers: [
    //     {
    //       visibility: "off",
    //     },
    //   ],
    // },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#46bcec",
          visibility: "on",
        },
      ],
    },
  ],
};

export default function Step2() {
  const {
    confirmUbication,
    next,
    handleNext,
    handleBack,
    onLoad,
    onUnmount,
    isLoaded,
    errors,
    searchMap,
  } = useStep2();
  const {
    address,
    setAddress,
    addressNumber,
    setAddressNumber,
    city,
    setCity,
    province,
    setProvince,
  } = useContext(leadFormContext);

  return (
    <Container>
      <Title>
        Para brindarte los datos de Nuestros Representantes en tu Zona,
        necesitamos que nos brindes tu ubicación
      </Title>
      <ColumnsContainer>
        <Column>
          <FieldsContainer>
            <TitleFields>Ingresá tu dirección</TitleFields>
            <FieldContainer>
              <Input
                color={errors.address ? "#FF3D00" : undefined}
                required
                value={address}
                onChange={({ target: { value } }) => setAddress(value)}
              />
              <Label color={errors.address ? "#FF3D00" : undefined}>
                Calle
              </Label>
            </FieldContainer>
            <FieldContainer>
              <Input
                color={errors.addressNumber ? "#FF3D00" : undefined}
                required
                value={addressNumber}
                onChange={({ target: { value } }) => setAddressNumber(value)}
              />
              <Label color={errors.addressNumber ? "#FF3D00" : undefined}>
                Altura
              </Label>
            </FieldContainer>
            <FieldContainer>
              <Input
                color={errors.city ? "#FF3D00" : undefined}
                required
                value={city}
                onChange={({ target: { value } }) => setCity(value)}
              />
              <Label color={errors.city ? "#FF3D00" : undefined}>
                Localidad
              </Label>
            </FieldContainer>
            <FieldContainer>
              <Input
                color={errors.province ? "#FF3D00" : undefined}
                required
                value={province}
                onChange={({ target: { value } }) => setProvince(value)}
              />
              <Label color={errors.province ? "#FF3D00" : undefined}>
                Provincia
              </Label>
            </FieldContainer>
            <ButtonContainerSearch style={{ justifyContent: "flex-end" }}>
              <Button type="primary" onClick={() => searchMap()}>
                BUSCAR
              </Button>
            </ButtonContainerSearch>
            <ButtonContainerMobile style={{ justifyContent: "flex-end" }}>
              <ButtonMobile onClick={() => searchMap()}>BUSCAR</ButtonMobile>
            </ButtonContainerMobile>
          </FieldsContainer>
        </Column>
        <Column>
          <ColumnContainer>
            <TitleSection>Ubicación</TitleSection>
            <MapContainer>
              {isLoaded && (
                <GoogleMap
                  options={mapOptions}
                  mapContainerStyle={containerStyle}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                ></GoogleMap>
              )}
            </MapContainer>
            <UbicationText>¿Es esta tu ubicación?</UbicationText>
            <Text style={{ marginBottom: "28px", minHeight: "12px" }}>
              {confirmUbication}
            </Text>
          </ColumnContainer>
        </Column>
      </ColumnsContainer>

      <ButtonsContainer>
        <Button type={"secondary"} onClick={handleBack}>
          ATRÁS
        </Button>
        <Button type={next ? "primary" : "disabled"} onClick={handleNext}>
          SIGUIENTE
        </Button>
      </ButtonsContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 26px;
  padding: 14px 34px;
  min-height: 80vh;
`;
const Title = styled.h3`
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #424242;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 5px 0px;
`;
const Text = styled.p`
  font-family: "D-DIN-PRO-Bold";
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #128297;
  text-align: center;
  margin-top: 16px;
`;
const UbicationText = styled.p`
  font-family: "D-DIN-PRO-Bold";
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #424242;
  margin-top: 16px;
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const MapContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;
const ButtonContainerSearch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const ButtonContainerMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
`;
const ButtonMobile = styled.button`
  width: 200px;
  height: 55px;
  padding: 20px;
  background: #128297;
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  cursor: pointer;
  &:focus {
    outline: 1px solid #29707d;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 43px;
    padding: 11px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
  @media (max-width: 768px) {
    gap: 10px;
  }
`;
const FieldContainer = styled.div`
  position: relative;
  height: 55px;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
  }
`;
const Input = styled.input`
  width: 100%;
  height: 55px;
  background: #ffffff;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const Label = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 11.5px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      line-height: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
const TitleSection = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #3c4858;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }
`;
const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;
const Column = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  @media (max-width: 768px) {
    width: 100%;
    gap: 10px;
    &:nth-child(2) {
      margin-top: 20px;
    }
  }
`;
const TitleFields = styled.p`
  font-family: "D-DIN-PRO-Bold";
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  color: #424242;
`;
